<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Veículos</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar veículo"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateVehicle">
            Adicionar Veículo
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, vehicles)">
        <div v-if="vehicles.data.length" class="w-100">
          <div
            v-for="vehicle in vehicles.data"
            :key="vehicle.id"
            class="w-100 mb--md">
            <getecma-vehicle-card
              :vehicle="vehicle" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhum veículo encontrado" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateVehicle } from '@/modules/vehicle/vehicle.routes';
import { fetchVehicles } from '@/modules/vehicle/vehicle.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaVehicleCard from '@/modules/vehicle/components/VehicleCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceVehicles',
  components: {
    GetecmaVehicleCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Veículos', path: this.$route.path },
      ],
      vehicles: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.fetchVehicles();
  },
  methods: {
    isMinLayout,
    fetchVehicles() {
      this.vehicles.loading = true;
      fetchVehicles({ limit: this.vehicles.pagination.limit, page: this.vehicles.pagination.page, search: this.searchQuery })
        .then(({ vehicles, total_vehicles: total }) => {
          if (this.searchQuery === '') {
            this.vehicles.data.push(...vehicles);
          } else {
            this.vehicles.data = vehicles;
          }
          Object.assign(this.vehicles.pagination, { total: parseInt(total), lastPage: Math.ceil(total / this.vehicles.pagination.limit) });
        })
        .catch(() => toastError('Erro ao obter veículos.'))
        .finally(() => { this.vehicles.loading = false; });
    },
    onCreateVehicle() {
      goToCreateVehicle(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchVehicles();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.vehicles.pagination.page = 1;
      this.vehicles.pagination.lastPage = 0;
      this.vehicles.data = [];
      this.fetchVehicles();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
