export function infinityScroll(event, content) {
  return new Promise((resolve, reject) => {
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    const { pagination: { lastPage, page }, loading } = content;
    if (!loading && (page < lastPage) && (offsetHeight + scrollTop >= scrollHeight * 0.9)) {
      content.pagination.page++;
      resolve();
    } else {
      reject();
    }
  });
}
