<template>
  <getecma-container :class="classesCard" :color="color" :bg-color="bgColor" :direction="direction" :grow="grow" :align-items="alignItems" :justify-content="justifyContent">
    <slot />
    <getecma-container v-if="$slots.footer" :bg-color="footerBgColor" :style="styles">
      <slot name="footer" />
    </getecma-container>
  </getecma-container>
</template>

<script>

import { mixinContainer } from '@/mixins/container/mixinContainer';
import { mixinColors } from '@/mixins/colors/mixinColors';

const mixinColorsCard = JSON.parse(JSON.stringify(mixinColors));
mixinColorsCard.props.bgColor.default = 'theme-100-900';

export default {
  name: 'GetecmaCard',
  mixins: [mixinContainer, mixinColorsCard],
  inheritAttrs: false,
  props: {
    border: {
      type: String,
      default: 'theme-400-900',
    },
    footerBgColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    classesCard() {
      return [
        {
          [`border--${this.border}`]: this.border,
        },
      ];
    },
    styles() {
      return 'border-top-left-radius:0;border-top-right-radius:0';
    },
  },
};
</script>
<style scoped lang="scss">
@import './Card.scss';
</style>
