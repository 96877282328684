<template>
  <svg :class="classes" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_273_10691)">
      <path
        d="M6.5 10.001C5.4 10.001 4.5 10.901 4.5 12.001C4.5 13.101 5.4 14.001 6.5 14.001C7.6 14.001 8.5 13.101 8.5 12.001C8.5 10.901
        7.6 10.001 6.5 10.001ZM18.5 10.001C17.4 10.001 16.5 10.901 16.5 12.001C16.5 13.101 17.4 14.001 18.5 14.001C19.6 14.001 20.5
        13.101 20.5 12.001C20.5 10.901 19.6 10.001 18.5 10.001ZM12.5 10.001C11.4 10.001 10.5 10.901 10.5 12.001C10.5 13.101 11.4 14.001
        12.5 14.001C13.6 14.001 14.5 13.101 14.5 12.001C14.5 10.901 13.6 10.001 12.5 10.001Z" />
    </g>
    <defs>
      <clipPath
        id="clip0_273_10691">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.000976562)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'DotsIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
