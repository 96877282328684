<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="item in items" :key="item.name" class="breadcrumb-item">
        <a :href="item.path">{{ item.name }}</a>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'GetecmaBreadcrumb',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
@import './Breadcrumb.scss';
</style>
