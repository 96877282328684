<template>
  <svg :class="classes" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7038 10.7969L19.4998 9.00092C20.045 8.45567 20.3176 8.18304 20.4634 7.88895C20.7407 7.3294 20.7407 6.67244 20.4634
      6.11289C20.3176 5.81879 20.045 5.54617 19.4998 5.00092C18.9545 4.45567 18.6819 4.18304 18.3878 4.03731C17.8282 3.76003
      17.1713 3.76003 16.6117 4.03731C16.3176 4.18304 16.045 4.45567 15.4998 5.00092L13.6811 6.81957C14.645 8.47017 16.0311 9.84574
      17.7038 10.7969ZM12.2267 8.27403L5.35615 15.1445C4.93109 15.5696 4.71856 15.7821 4.57883 16.0432C4.43909 16.3043 4.38015 16.599
      4.26226 17.1885L3.64686 20.2655C3.58034 20.5981 3.54708 20.7644 3.64168 20.859C3.73629 20.9536 3.9026 20.9204 4.23521
      20.8538L7.31219 20.2384C7.90164 20.1205 8.19637 20.0616 8.45746 19.9219C8.71856 19.7821 8.93109 19.5696 9.35615 19.1445L16.2456
      12.2551C14.6239 11.2395 13.2522 9.87719 12.2267 8.27403Z" />
  </svg>
</template>

<script>
import { mixinClasses } from '@/mixins/classes/mixinClasses';
import { mixinIcon } from '@/mixins/icon/mixinIcon';
import { mixinSvg } from '@/mixins/svg/mixinSvg';

export default {
  name: 'EditIcon',
  mixins: [mixinIcon, mixinSvg, mixinClasses],
};
</script>
