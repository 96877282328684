<template>
  <getecma-layout direction="column">
    <getecma-container direction="column" :class="['col-12', contentClass]" grow="1" :justify-content="justifyContent">
      <slot name="content" />
    </getecma-container>
  </getecma-layout>
</template>

<script>
export default {
  name: 'GetecmaSingleContentLayout',
  props: {
    justifyContent: {
      type: String,
      default: 'start',
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
};
</script>
